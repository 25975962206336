@import '../../styles/index.scss';

.ContactButton{
    position: fixed;
    bottom: 1vw;
    right: 1vw;
    z-index: 1000;
    text-align: left !important;
    @extend .PrimaryButton;
    border-color: #aaff00 !important;
    

}

.ContactText{
    font-weight: 600 !important;
    @include SecondarySubTitle(1vw);
}

.MailIcon{
    margin-right: 0.6vw;
    color: $secondary-text-color;
    //color: blue;
}

.ContactModalBody{
    max-height: 90vh;
}
