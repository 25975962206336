@import "../../styles/index.scss";

$subtitle-size: max(1.2rem,6px);

.HeaderContainer{
    display: flex;
    justify-content: space-between;
    padding: 10px;
    z-index: 10;
    
    background-color: transparent;
    @include PaddingTopBottom(5vh !important);
    transition: all $transition-time ease-out;
    
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 100%;

    // for navbar overflow
    overflow-x: hidden;
    max-width: 100%;
    box-sizing: border-box;
}

// to prevent navbar horizontal overflow
body {
    margin: 0;
    padding: 0;
    overflow-x: hidden; // Prevent horizontal scrolling
    width: 100%; // Match the viewport width
    box-sizing: border-box;
}

.smallerLogo {
    fill: url(#gradient);
    max-width: 50px;
    height: auto;
    //width: 6vw;
    //height: 14vh;
    display: block;
    position: relative;
    margin-right: auto;
    /*animation: pulse 8s infinite ease-in-out; */
}

.HeaderContainerShrunk{
    background-color: $primary-color;
    @include PaddingTopBottom(0vh !important);
    transition: all $transition-time ease-out;
}

%NavBrand {
    text-decoration: none !important;
    color: $accent-color;
    font-family: $brand-font;
}

.NavBrand {
    @extend %NavBrand;
    font-size: 4vh; 
    transition: font-size $transition-time;
    margin-left: auto; 
}

.NavBrandShrunk {
    @extend %NavBrand;
    font-size: 2vw;
    transition: font-size $transition-time;
    font-size: 1rem;
    color: #555;
    padding-right: 5px;
    
}

.NavBrandContainer{
    display: flex;
    align-items: center;
    display: flex;
    justify-content: flex-end; /* Aligns content to the right */
    align-items: center; /* Vertically centers */
    &:hover a{
        color: $accent-color !important;
    }
    &:active a,
    &:focus a {
        color: $accent-color !important;
    }
    &:hover svg{
        transform: rotate(360deg);
        // color: $accent-hover-color !important;
    }
}

.NavLink{
    @include PaddingLeftRight(1vw);
    text-decoration: none !important;
    @include PrimarySubTitle($subtitle-size);
    &:hover{
        color: $primary-text-hover-color;
    }
    &:active,
    &:focus{
        color: $primary-text-color;
    }
}

.NavContainer:hover{
    opacity: 1;
    background-color: transparent ;
    border-width: 10px;
    border-radius: 5px;
}

.SignInButton{
    @extend .PrimaryOutlineButton;
    @include PrimarySubTitle($subtitle-size);
    font-family: 'Aboreto';
    margin-left: auto;  // Align button to the right
    margin-right: 2vh;
}

@media only screen and (max-width: 1026px) {
    // .dropbtn {
    //     display: none;
    // }

    .links {
        display: none;
    }

    .burger-menu {
        display: block;
        font-size: 4vh;
        cursor: pointer;
    }

    .SignInButton {
        display: none;
    }
}

//ignore for now, put in footer later
.UBCLogo{
    /*-webkit-filter: brightness(0) invert(1); /* Safari 6.0 - 9.0 
    filter: brightness(0) invert(1); 
    */
    width: 70px;
    height: auto;
    bottom: 80%;
    position: relative;
    right: 10%;
    background-color: #04AA6D;
    display: none;
}


/* Dropdown Button */
.dropbtn {
    background-color: transparent;
    color: white;
    padding: .7vh 1vh;
    font-size: 1.5vh;
    //height: 0.7vh;
    margin: 0;
    font-size: 2 vw;
    border: none;
    align-items: center;
    //margin-left: auto;
    font-family: $secondary-text-font;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    //margin-right: 0;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
    display: none;
    position: absolute;
    right: 0;
    background-color: #f1f1f1;
    min-width: 80px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    padding: 12px 0;
}

/* Links inside the dropdown */
.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    font-size: 1rem;
}

// /* Change color of dropdown links on hover */
// .dropdown-content a:hover {background-color: #ddd;}

// /* Show the dropdown menu on hover */
// .dropdown:hover .dropdown-content {display: block;}

// /* Change the background color of the dropdown button when the dropdown content is shown */
// .dropdown:hover .dropbtn {background-color: #252ba8;} 

.dropdown:hover .dropdown-content,
.dropbtn:focus + .dropdown-content {
    display: block;
}

.dropdown:hover .dropdown-content,
.dropbtn:focus {
    background-color: #252ba8;
}


// hide dropdown menu for desktop
@media only screen and (min-width: 1026px) {
    .dropbtn {
        display: none;
    }
    .dropdown {
        display: none;
    }
    .dropdown-content{
        display: none;
    }
    .dropdown:hover{
        display: none;
    }
}



//adjust all elements for mobile devices, portrait mode only

@media only screen and (max-width: 500px) {

    // .links{
    //     display: none;
    // }

    .HeaderContainer{
        
        background-color: transparent;
        @include PaddingTopBottom(5vh !important);
        transition: all $transition-time ease-out;
        display: flex;  // Added for flexbox alignment
        justify-content: flex-end;  // Aligns children to the right
        align-items: center;  // Centers items vertically
    }
    .smallerLogo {
        //fill: url(#gradient);
        //width: 6vh;
        max-width: 40px;
        // height: 7vh;
        height: auto;
        display: contents;
        // left: 0%;
        // position: relative;
        // background-color: transparent;
        /* animation: pulse 8s infinite ease-in-out; */
    }
    .HeaderContainerShrunk{
        background-color: $primary-color;
        @include PaddingTopBottom(0vh !important);
        transition: all $transition-time ease-out;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    
    %NavBrand {
        text-decoration: none !important;
        //color: $accent-color;
        color: white;
        font-family: $brand-font;
    }
    .UBCLogo {
        display: none;
    }

    
    .NavBrand {
        @extend %NavBrand;
        font-size: 4vh;
        transition: font-size $transition-time;
        margin-left: auto;  // Ensure the brand is pushed to the right
    }
    .NavBrandShrunk {
        // @extend %NavBrand;
        font-size: 2vh;
        // left: 0%;
        // position: relative;
        // transition: font-size $transition-time;
    }
    .NavLink{
        @include PaddingLeftRight(1vw);
        text-decoration: none !important;
        @include PrimarySubTitle($subtitle-size);
        &:hover{
            color: $primary-text-hover-color;
        }
        &:active{
            color: $primary-text-color;
        }
        &:focus{
            color: $primary-text-color;
        }
    }
    .HeaderLogo{
        //max-height: 10vh;
        //min-width: 6vw;
        top: auto;
        color: $accent-color;
        transition: transform 1s ease;
        position: relative;
        background-color: transparent;
        
    }
    .NavContainer:hover{
        opacity: 1;
        background-color: transparent ;
        border-width: 10px;
        border-radius: 5px;
    }
    //W3Schools Tutorial
    
    /* Dropdown Button */
    .dropbtn {
        // background-color: transparent;
        // color: rgb(255, 255, 255);
        // font-size: 1.5 vh;
        // font-size: 2vh;
        // border: none;
        // //margin-left: 20%;
        // align-items: center;
        // position: relative;
        // //display: contents;
        // //display: flex;
        display: flex;
        background-color: transparent;
        color: white;
        padding: .7vh 1vh;
        margin: 0;
        border: none;
        align-items: center;
        font-family: $secondary-text-font;
        font-size: 2vh; // Keep consistent font size
    }

    .dropbtnShrunk{
        background-color: #2882cc;
        color: rgb(255, 255, 255);
        font-size: 1.5 vw;
        border: none;
        left: 0%;
        position: relative;
    }
    
    /* The container <div> - needed to position the dropdown content */
    .dropdown {
        position: relative;
        display: block;
        //margin-right: auto;
        //margin-left: 0%;
        //width: 5vh;
        width: auto;
        margin: 0 auto;
    }
    
    /* Dropdown Content (Hidden by Default) */
    .dropdown-content {
        display: none;
        //position: absolute;
        background-color: #f1f1f1;
        max-width: 150px; //10vh
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        z-index: 1;
    }
    

    
    /* Links inside the dropdown */
    .dropdown-content a {
        color: black;
        //padding: 12px 16px;
        text-decoration: none;
        display: block;
        // font-size: 20%;
        //height: max(0.7vh, 10px);
        font-size: 1rem;
    }

    /* Change color of dropdown links on hover */
    .dropdown-content a:hover {background-color: #ddd;}
    
    /* Show the dropdown menu on hover */
    .dropbtn:hover .dropdown-content {display: block;}

    .dropbtnShrunk:hover .dropdown-content {display: block;}
    
    /* Change the background color of the dropdown button when the dropdown content is shown */
    .dropdown:hover .dropbtn {background-color: transparent;} 
}
