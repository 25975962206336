@import './index.scss';

.App{
    background-image: linear-gradient(to bottom, $background-color-top, $background-color-bottom);
    background-size: cover;
    background-position-x: 50%;
    background-position-y: 0px;
    box-sizing: border-box;
    display: block;
    padding-top: 17vh;
    width: 100%;
    height: 100%;
    max-width: 100%;
    div {
        font-weight: 350;
    }
}
