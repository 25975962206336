@import "../../styles/index.scss";

$form-color: $secondary-color;
$form-border-radius: 5px;
$form-opacity: 0.6;

.disabled{
    pointer-events: none;
    cursor: default;
    color:gray
}

.JoinForm{
    display: block;
    margin: auto;
    height: 50vh;
    width: 50vw;
    background-color:rgba($form-color, 0) !important;
}

.JoinFormContainer{
    margin-top: 2vh;
    margin-bottom: 50vh;
}

.JoinFormCard{
    border-radius: $form-border-radius !important;
    background-color:rgba($form-color, $form-opacity) !important;
}

.JoinFormHeader{
    border-top-left-radius: $form-border-radius !important;
    border-top-right-radius: $form-border-radius !important;
    font-weight: 500;
    background-color: $accent-color !important;
    @include SecondarySubTitle(1.5vw);
}

.ApplyButtonText{
    @include SecondarySubTitle(1.1vw);
}

.AltLink{
    text-align: center;
    color: $accent-color;
    font-size: 2vw;
    font-weight: 300;
    font-family: $subheadings;
    width: 100%;
    margin-bottom: 3vh;
}


@media only screen and (max-width: 1026px) {
    .JoinForm{
        display: block;
        margin: auto;
        height: 225vh;
        width: 50vw;
        background-color:rgba($form-color, 0) !important;
    }
    
    .JoinFormContainer{
        margin-top: 2vh;
        margin-bottom: 25vh;
    }
    
    .JoinFormCard{
        border-radius: $form-border-radius !important;
        background-color:rgba($form-color, $form-opacity) !important;
    }
    
    .JoinFormHeader{
        border-top-left-radius: $form-border-radius !important;
        border-top-right-radius: $form-border-radius !important;
        font-weight: 500;
        background-color: $accent-color !important;
        @include SecondarySubTitle(1.5vw);
    }
    
    .ApplyButtonText{
        @include SecondarySubTitle(1.1vw);
    }
}
