@import '../../styles/index.scss';

.SponsorCard{
    text-align: center;
    background-color: $accent-color !important;
    margin-bottom: 50px;
    @include MarginLeftRight(10px);
    width: max(14vw, 120px);
    height: max(14vw, 120px);
    transition: transform $transition-time;
    &:hover{
        transform: scale(1.15);
        opacity: 0.8;
    }
}

.SponsorCardBody {
    display : flex;
    align-items : center;
    margin-top: auto;
    margin-bottom: auto;
}

.SponsorRow {
    @include MarginTopBottom(40px); 
}

.SponsorImage {
    width: 100%;
}

.SponsorClass .ImageContainer {
    border: none;
    height: auto;
    &:hover{
        animation: spin 1s ease-in;
    }
}
