$primary-color: #31393a;
$secondary-color: #dedada;

$accent-color:#fed136;
$accent-hover-color: #fff;

$primary-text-color: #fff;
$primary-text-hover-color: rgb(218, 218, 218);

$secondary-text-color: #1f2525;
$secondary-text-hover-color: rgb(139, 139, 139);

$brand-font: 'Chakra Petch'; /* Carter One */
$primary-text-font: 'Montserrat';
$secondary-text-font: 'Bebas Neue'; /* Allerta Stencil */
$subheadings: 'Chakra Petch';

$background-color-top: #0b282e; /*38abc1*/
$background-color-bottom: #051f47;

$transition-time: 0.5s;

$content-font-size: 1.3vw;
$content-font-size-c: 12px;
$content-font-size-v: 0.5vw;

