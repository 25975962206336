@import "../../styles/index.scss";

.CenteredBlurb {
  color: $secondary-color;
  font-size: 3vh;
  font-weight: 600;
  display: block;
  @include MarginTopBottom(3vh);
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  text-align: center;
}

.TeamTitle {
  margin-top: 3vh;
  text-align: center;
  font-size: calc(7px + 2.5vw);
  color: $accent-color;
  font-weight: 600px;
  font-family: $secondary-text-font;
  display: block;
  width: 20.8vw;
  margin-right: 0px;
  margin-bottom: 3vh;
}

.MemberCard {
    text-align: center;
    background-color: $accent-color !important;
    margin-bottom: 4vw;
    // @include MarginLeftRight(10px);
    width: max(16.7vw, 105px);
    height: max(22vw, 225px);
    // padding-top: 20px;
    padding-top: 1vw;
    margin-left: 2vw;
    margin-right: 2vw;
    white-space: pre-line;
}

.MemberCard a:link {
  color: navy;
}

.MemberCard a:visited {
  color: navy;
}

.MemberCard a:hover {
  color: royalblue;
}

.MemberCardBody {
    display: block;
    margin-top: auto;
    margin-bottom: auto;
}

.MemberCardTitle {
    // font-size: 20px !important;
    // padding-top: 30px;
    // font-weight: 600 !important;
    font-size: max(1.1vw, 10px) !important;
    padding-top: max(1.4vw, 7px); //1.4 7
    font-weight: 600 !important;
    color: $secondary-text-color;
}

.MemberCardSubtitle{
    // font-size: 19px !important;
    // padding-top: 10px;
    // font-weight: 400 !important;
    font-size: max(0.85vw, 8px) !important;
    padding-top: max(0.7vw, 0px);
    font-weight: 400 !important;
    color: $secondary-text-color;
}

.MemberRow {
    @include MarginTopBottom(40px);
}

.MemberImage {
    display: block;
    margin-left: auto;
    margin-right: auto;
    // height: 140px;
    // width: 140px;
    // border-radius: 150px;
    height: max(7.5vw, 50px);
    width: max(7.5vw, 50px);
    border-radius: 150px;
}

.MemberCardText{
    font-style: italic;
    font-size: max(1vw, 10px);
    // padding-top: 15px;
    padding-top: 0vw;
    font-weight: 400 !important;
    color: $secondary-text-color;
}

.MemberIconLarge{
    font-size: max(6vw, 60px);
    @include PaddingTopBottom(1vw);
    color: $primary-color;
}

.MemberIconSmall{
    font-size: max(3.5vw, 35px);
    @include PaddingTopBottom(0.5vw);
    color: $primary-color;
}

.MemberCardHeader{
    font-size: max(2vw, 20px);
    font-family: $primary-text-font;
    background-color: $accent-color !important;
    color: $secondary-text-color;
}

.MemberBodyHeader {
    font-size: max(2.7vw, 27px);
    font-family: $secondary-text-font;
}

.MemberBodyText {
    margin-top: max(2vh, 20px);
    font-size: $content-font-size;
    font-family: $primary-text-font;
}

.MemberBodyItemHeader {
    font-size: max(1.4vw, 14px);
    font-family: $primary-text-font;
    font-weight: 500;
}

.MemberBodyItemFrame {
    height: max(35vh, 350px);
    width: max(45vw, 450px);
    border: 2px solid $primary-color;
    border-radius: 5px;
    @include MarginTopBottom(2vh);
}

.MemberBodyItemContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.MemberBodyItem {
    background-color: $secondary-color;
    border-radius: 5px;
    border: 1px solid rgb(173, 173, 173);
    width: 97%;
    padding: 1.5vh;
    display: flex;
}

$member-body-item-icon-height: 6vh;

.MemberBodyItemIconContainer {
    height: $member-body-item-icon-height;
    width: $member-body-item-icon-height;
}

.MemberBodyItemIcon {
    border: 2px solid $accent-color;
    border-radius: 50%;
    width: 100%;
    height: 100%;
}

.MemberBodyItemTextPrimary {
    font-size: max(1.2vw, 12px);
    font-family: $primary-text-font;
    margin-left: 1vw;
    color: $secondary-text-color;
    line-height: $member-body-item-icon-height;
}

.MemberBodyItemTextSecondary {
    font-size: max(0.9vw, 9px);
    font-family: $primary-text-font;
    margin-left: 1vw;
    color: rgb(73, 73, 73);
    line-height: $member-body-item-icon-height;
}