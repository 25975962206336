@import '../../styles/index.scss';

.ImageContainer{
    @include MarginTopBottom(2vh);
    text-align: center;
    position: relative;
    border: 3px solid $secondary-color;
    border-radius: 5px;
    height: 100%;
    filter: grayscale(0.2);
}

.Image{
    width: 100%;
    height: 100%;
    opacity: 0.95;
}
