@import "../../styles/index.scss";
@import "../../styles/variables-opts/variables-opt1.scss";

.Logo {
    fill: url(#gradient);
    width: 51vw;
    height: 51vh;
    display: block;
    margin-left: auto;
    margin-right: auto;
    animation: pulse 8s infinite ease-in-out;
}

.LogoSpin {
    animation: spin 12s infinite linear;
}

@keyframes pulse {
    0% { transform: scale(0.95); opacity: 0.8; }
    50% { transform: scale(1); opacity: 1; }
    100% { transform: scale(0.95); opacity: 0.8; }
}

@keyframes spin {
    from { 
        transform:  rotate(0deg);
        transform-origin: 50% 50%; 
    } to { 
        transform: rotate(359deg); 
        transform-origin: 50% 50%;
    }
}

.PitchImageContainer{
    /* height: 90%;
    width: 45vw;*/
    margin-top: 27vh;
}
.AboutUsHeader{
    font-family: $primary-text-font;
    text-align: left;
    color: $accent-color;
    /* font-size: 2.6vw; */
    font-size: calc(20px + 3vw);
    margin-top: 3vh;
    margin-left: 9vw;
    font-weight: 300;
    width: 100%;
}

.AboutUsBody{
    color: $secondary-color;
    font-size: calc(#{$content-font-size-c} + #{$content-font-size-v});
    font-weight: 600;
    display: block;
    margin-left: 9vw;
}

.PitchHeader{
    font-family: $primary-text-font;
    text-align: left;
    color: $accent-color;
    /* font-size: 2.6vw; */
    font-size: calc(20px + 3vw);
    margin-top: 10vh;
    margin-left: 1vw;
    font-weight: 300;
    width: 100%;
}

.HeaderSeparator{
    height: 5wh;
    color: $background-color-bottom;
}

.PitchBody{
    color: $secondary-color;
    font-size: calc(#{$content-font-size-c} + #{$content-font-size-v});
    font-weight: 600;
    display: block;
    margin-left: 1vw;
    margin-bottom: 3vw;
}

.GoalImageContainer{
    // height: 90%;
    /* width: 45vw; */
}

.GoalHeader{
    /* font-size: 3vw; */
    font-size: calc(20px + 3vw);
    padding-top:  4vh;
    font-family: $secondary-text-font;
    background-color: clear;
    text-align: center;
}

.GoalBody{
    @include MarginTopBottom(2vh);
    color: $secondary-text-color;
    font-size: calc(#{$content-font-size-c} + #{$content-font-size-v});
    display: block;
}

.ProjectsContainer{
    margin-bottom: 10vh
}

.ProjectsCarousel{
    margin: auto;
    /* height: 100%; */
    /* width: 100%; */
}

.ProjectsImageContainer{
    margin: auto;
    /* height: 68vh; */
    /* width: 90%; */
}

.ProjectsImageCaption{
    color: $accent-color;
    text-align: center;
}

.ProjectsHeader{
    margin-top: -3vh;
    text-align: center;
    /* font-size: 5vw; */
    font-size: calc(20px + 3vw);
    color: $accent-color;
    font-weight: 600px;
    font-family: $secondary-text-font;
}

.ProjectsSubheader{
    text-align: center;
    /* font-size: 5vw; */
    font-size: calc(20px + 2vw);
    color: $accent-color;
    font-weight: 600px;
    font-family: $secondary-text-font;
}

.ProjectsBody{
    margin: 5vh;
    font-size: calc(#{$content-font-size-c} + #{$content-font-size-v});
    color: $secondary-color;
}
