@import '../../styles/index.scss';

.ViewContainer {
    padding-top: 2vh;
    overflow-x: hidden;
}

.ViewImageContainer {
    height: 100%;
}

.ViewHeaderTitle{
    text-align: center;
    color: $accent-color;
    font-size: 10vh;
    font-weight: 300;
    font-family: $secondary-text-font;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 1%;
    width: 100%;
}

.ViewHeaderHeadingOne{
    text-align: center;
    color: $accent-color;
    font-size: 2vw;
    font-weight: 300;
    font-family: $subheadings;
    width: 100%;
    margin-bottom: 1vh;
}

.ViewHeaderBlurb{
    color: $secondary-color;
    font-size: 3vh;
    font-weight: 600;
    display: block;
    @include MarginTopBottom(3vh);
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    padding-left: 6%;
}

