@import "../../styles/index.scss";

.FooterContainer{
    text-align: center;
    @include PaddingTopBottom(3vh);
    background-color: $primary-color;
    border-top: 2px solid $primary-text-color;
}

.HeaderContainer{
    background-color: transparent;
    @include PaddingTopBottom(5vh !important);
    transition: all $transition-time ease-out;
}

.SocialIcon{
    font-size: 2.1vw;
    color: $primary-text-color;
    @include MarginLeftRight(1.5vw);
    &:hover{
        color: $primary-text-hover-color;
    }
}

.CopyrightText{
    margin: auto;
    font-size: 0.9vw;
    color: $primary-text-color;
}
