@import 'variables';
$green: #aaff00;


.PrimaryButton{
  font-size: 1.2vw;
  border-color: $green !important;
  background-color: $green !important;
  &:hover{
    opacity: 0.7;
  }
  &:active{
    border-color: $green !important;
    background-color: $green !important;
  }
  &:focus{
    border-color: $green !important;
    background-color: $green !important;
    box-shadow: 0 0 0 0.2rem $green !important;
  }
}

.PrimaryOutlineButton{
    font-size: 1.2vw;
    color: $accent-color !important;
    border-color: $accent-color !important;
    &:hover{
      background-color: $accent-color !important;
    }
    &:active{
      background-color: $accent-color !important;
    }
    &:focus{
      box-shadow: 0 0 0 0.2rem $accent-hover-color !important;
    }
}

.SecondaryButton{
    font-size: 1.2vw;
    border-color: $primary-color !important;
    color: $primary-text-color !important;
    background-color: $primary-color !important;
    &:hover{
      opacity: 0.7;
    }
    &:active{
      border-color: $secondary-color !important;
      color: $secondary-text-color !important;
      background-color: $secondary-color !important;
    }
}

.HeaderSeperator{
  width: 100%;
  height: 7vh;
  opacity: 0.3;
  background-color: $primary-color;
  @include MarginTopBottom(30px);
}

.HeaderBuffer {
  height: 60px;
}

.ContentSeperatorTop {
  height: 0.5vw;
  margin-top: 5vh;
  background-color: $secondary-color;
}

.ContentSeperatorBottom {
  height: 0.5vw;
  margin-bottom: 10vh;
  background-color: $secondary-color;
}

.ContentContainer {
  width: 100%;
  background-color: whitesmoke;
  padding-bottom: 5vh;
  @include PaddingLeftRight(1.4vw);
}
