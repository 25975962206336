// Overriding base html classes

body {
  width: 100%;
  height: 100%;
  margin-right: -15px;
  padding: 0px;
  // overflow-x: hidden; 
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
  &:hover {
    text-decoration: none;
    outline: none;
  }
  &:focus {
    text-decoration: none;
    outline: none;
  }
  &:active {
    text-decoration: none;
    outline: none;
  }
}

button::-moz-focus-inner {
  border: 0;
}

hr {
  margin: 2vh;
}

// overriding bootstrap classes

.form-control:valid {
  background-image: none !important;
}


.form-control:invalid {
  background-image: none !important;
}

.row {
  margin-right: 0px !important;
}
